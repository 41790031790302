<template>
  <div class="page-content-inner">

    <h2 class="mb-4">{{ $t('profile.profile') }} <i class="icon-feather-arrow-right"></i> {{ userProfile.name }}</h2>

    <div uk-grid="" class="uk-grid">
      <div class="uk-width-1-4@m uk-first-column">

        <div class="uk-card-default rounded text-center p-4">

          <div class="user-profile-photo  m-auto">
            <img :src="userProfile.photo_link" :alt="userProfile.name">
          </div>

          <h4 class="mb-4 mt-3"> {{ userProfile.fullname }}</h4>
          <button @click="$router.push({ name: 'User.messages' })" type="button" class="btn btn-info uk-first-column mb-2"><i class="uil-envelope-alt"></i> Mesaj Gönder</button>
          <button @click="$router.push({ name: 'User.wall',query: { user: userProfile.id } })" type="button" class="btn btn-outline uk-first-column"><i class="uil-coffee"></i> Duvarına Bak</button>

        </div>

      </div>

      <div class="uk-width-expand@m">

        <div class="uk-card-default rounded">
          <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
            <h5 class="mb-0"><i class="icon-feather-user"></i> {{ $t('profile.member_info') }}</h5>
          </div>
          <hr class="m-0">
          <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid" uk-grid="">
            <div class="uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.name') }}</h6>
              <p> {{ userProfile.name + ' ' + userProfile.surname }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.surname') }}</h6>
              <p> {{ userProfile.surname }} </p>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.dob') }}</h6>
              <p> {{ userProfile.dob }} </p>
            </div>
            <div class="uk-grid-margin">
              <h6 class="uk-text-bold"> {{ $t('profile.email') }} </h6>
              <p> {{ userProfile.email }} </p>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.phone') }} </h6>
              <p> {{ userProfile.phone }} </p>
            </div>
          </div>
        </div>

        <div class="uk-card-default rounded mt-4">
          <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
            <h5 class="mb-0"><i class="icon-feather-briefcase"></i> {{ $t('profile.company_profile') }} </h5>
          </div>
          <hr class="m-0">
          <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid" uk-grid="">
            <div class="uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.company_name') }} </h6>
              <p> {{ userProfile && userProfile.company ? userProfile.company.name : '-' }} </p>
            </div>
            <div>
              <h6 class="uk-text-bold"> {{ $t('profile.position') }} </h6>
              <p> {{ userProfile && userProfile.position ? userProfile.position.name : '-' }} </p>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.title') }} </h6>
              <p> {{ userProfile && userProfile.title ? userProfile.title.name : '-' }} </p>
            </div>
            <div class="uk-grid-margin">
              <h6 class="uk-text-bold"> {{ $t('profile.gm_region') }} </h6>
              <p> {{ userProfile && userProfile.region ? userProfile.region.name : '-' }} </p>
            </div>
            <div class="uk-grid-margin uk-first-column">
              <h6 class="uk-text-bold"> {{ $t('profile.unit') }} </h6>
              <p> {{ userProfile && userProfile.branch ? userProfile.branch.name : '-' }} </p>
            </div>
            <div class="uk-grid-margin">
              <h6 class="uk-text-bold"> {{ $t('profile.branch') }} </h6>
              <p> {{ userProfile && userProfile.store ? userProfile.store.name : '-' }} </p>
            </div>
          </div>
        </div>

        <div class="uk-width-4-4@m mt-4" v-if="userProfile.collections.length">
          <h4>{{ $t('profile.user_collections') }}</h4>
          <div class="uk-position-relative uk-slider uk-slider-container" tabindex="-1" uk-slider="autoplay: true">

            <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-3@s uk-grid" style="transform: translate3d(-279.906px, 0px, 0px);">

              <template v-for="collection in userProfile.collections">
                <collection
                    @onClickCard="collectionDetails"
                    :id="collection.id"
                    :title="collection.name"
                    :image="collection.cover_image_link"
                    :counter="collection.collection_contents_count+collection.collection_training_contents_count"
                ></collection>
              </template>
            </ul>
            <div class="uk-flex uk-flex-center mt-3">
              <ul class="uk-slider-nav uk-dotnav"><li uk-slider-item="0" class=""><a href="#"></a></li><li uk-slider-item="1" class=""><a href="#"></a></li><li uk-slider-item="2" class="uk-active"><a href="#"></a></li><li uk-slider-item="3" class=""><a href="#"></a></li><li uk-slider-item="4" class=""><a href="#"></a></li></ul>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import {BASE_URL, CURRENT_ITEM, GET_USER} from "@/core/services/store/user.module";
import module from "@/core/services/store/user.module";
import moment from "moment";
import store from "@/core/services";
import Collection from "@/view/components/index/Collection";

export default {
  name: "UserShow",
  components: { Collection },

  methods: {
    collectionDetails(payload){
      this.$router.push({name: 'user.collection-detail', params: {id: payload}})
    },
  },
  computed: {

    userProfile: {
      get() {
        let UserShow = store.getters[this.$options.name + '/' + CURRENT_ITEM];

        if (typeof UserShow.user !== "undefined") {
          UserShow.user.dob = UserShow.user.dob ? moment(UserShow.user.dob).format('YYYY-MM-DD') : '-'
          UserShow.user.fullname = UserShow.user.name + ' ' + UserShow.user.surname;

          if (typeof UserShow.collections == "undefined") {
            UserShow.collections = [];
          }
          else {
            UserShow.user.collections = UserShow.collections;
          }

          return UserShow.user;
        }

        return {};
      },
      set(value) {}
    }
  },
  beforeCreate () {
    function registerStoreModule (moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(this.$options.name, module)
  },

  mounted() {
    this.$store.dispatch(this.$options.name + '/' + GET_USER, {
      url: BASE_URL + '/show-to-guest/' + this.$route.params.id,
      acceptPromise: true,
    })
  },
}
</script>

<style scoped>

</style>